'use client';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Tooltip } from '@chakra-ui/react';
import cn from 'classnames';
import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import React, { useEffect, useRef } from 'react';

import Styles from './Nav.module.scss';

const menuItems = [
  { label: 'Vehicle Style', path: '/build-price' },
  { label: 'Model Selection', path: '/build-price/:car' },
  { label: 'Colour', path: '/build-price/:car/:model' },
  { label: 'Financing', path: '/build-price/:car/:model/:color' },
  { label: 'Summary', path: '/build-price/:car/:model/:color/summary' },
];

const BuildPriceHeader = () => {
  const navRef = useRef<HTMLDivElement>(null);
  const pathname = usePathname();
  const router = useRouter();

  useEffect(() => {
    const activeLink = document.querySelector(
      `.${Styles.menuLinkActive}`,
    ) as HTMLAnchorElement;
    const nav = navRef.current;

    if (activeLink && nav) {
      const linkRect = activeLink.getBoundingClientRect();
      const navRect = nav.getBoundingClientRect();

      const scrollAmount =
        linkRect.left - navRect.left + linkRect.width / 2 - navRect.width / 2;

      nav.scrollLeft += scrollAmount;
    }
  }, [pathname]);

  const getActiveMenuIndex = () => {
    const pathParts = pathname.split('/').filter(Boolean);

    for (let i = menuItems.length - 1; i >= 0; i--) {
      const itemParts = menuItems[i]?.path.split('/').filter(Boolean);

      if (itemParts!.length <= pathParts.length) {
        const match = itemParts!.every(
          (part, index) => part.startsWith(':') || part === pathParts[index],
        );

        if (match) return i;
      }
    }

    return -1;
  };

  const generateHref = (itemPath: string) => {
    const pathParts = pathname.split('/').filter(Boolean);
    const itemParts = itemPath.split('/').filter(Boolean);

    return (
      '/' +
      itemParts
        .map((part, index) => {
          if (part.startsWith(':')) {
            return pathParts[index] || part;
          }
          return part;
        })
        .join('/')
    );
  };

  const isLinkDisabled = (index: number) => {
    const pathParts = pathname.split('/').filter(Boolean);

    return index > pathParts.length - 1;
  };

  const getTooltipMessage = (index: number) => {
    const messages = [
      'Select a vehicle style first, then click on Build Yours to continue.',
      'Select a model first, then click on Next to continue.',
      'Select a colour first, then click on Financing to continue',
      'Complete financing options first, then click on Summary to continue',
    ];

    return messages[index - 1] || '';
  };

  const handleBackClick = () => {
    const currentIndex = getActiveMenuIndex();

    if (currentIndex > 0) {
      const previousPath = generateHref(menuItems[currentIndex - 1]!.path);

      router.push(previousPath);
    } else {
      router.push('/build-price');
    }
  };

  const activeIndex = getActiveMenuIndex();

  return (
    <nav
      className={cn(
        Styles.buildPriceNavBar,
        pathname === '/build-price' && Styles.darkNavBar,
      )}
    >
      <Box className={Styles.mainNav}>
        <Flex className={Styles.flexWrapper} alignItems={'center'}>
          {getActiveMenuIndex() !== 0 ? (
            <Button
              onClick={handleBackClick}
              className={Styles.backBtn}
              padding={0}
              role="button"
            >
              <ChevronLeftIcon role="button" />
              <Box className={Styles.backBtnText} role="button">
                Back
              </Box>
            </Button>
          ) : (
            <Box />
          )}
          <Box className={Styles.KiaOneNavigation} ref={navRef}>
            <ul>
              {menuItems.map((item, index) => (
                <li key={index} className={Styles.menuItem}>
                  <Tooltip
                    label={getTooltipMessage(index)}
                    isDisabled={!isLinkDisabled(index)}
                  >
                    <Box>
                      {isLinkDisabled(index) ? (
                        <span
                          className={cn(
                            Styles.menuLink,
                            Styles.menuLinkDisabled,
                          )}
                        >
                          {item.label}
                        </span>
                      ) : (
                        <Link
                          className={cn(
                            Styles.menuLink,
                            index === activeIndex && Styles.menuLinkActive,
                          )}
                          href={generateHref(item.path)}
                          passHref
                        >
                          {item.label}
                        </Link>
                      )}
                    </Box>
                  </Tooltip>
                </li>
              ))}
            </ul>
          </Box>
          <Box w={{ base: '0', md: '55px' }} />
        </Flex>
      </Box>
    </nav>
  );
};

export default BuildPriceHeader;
